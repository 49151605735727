'use client';

import { FC } from 'react';
import NextImage from 'next/image';

import { LandingMemberNode } from 'generated/types';

interface IMemberSlide {
  member: LandingMemberNode;
}

export const MemberSlide: FC<IMemberSlide> = (props) => {
  const {
    member,
  } = props;

  return (
    <div className="flex flex-col gap-3xl">
      <div className="border border-gray-50 rounded-radius-3xl overflow-hidden">
        {!!member?.image && (
          <NextImage src={member.image as string} width={280} height={296} alt="member-image" className={"h-[280px] object-cover"}/>
        )}
      </div>
      <div className="flex flex-col gap-xl">
        <div className="flex flex-col gap-xs">
          <p className="text-x-large font-semibold text-gray-950">{member?.name}</p>
          <p className="text-large text-success-500">{member?.position || member?.about}</p>
        </div>
        <p className="text-gray-500 text-medium">{member?.about}</p>
      </div>
    </div>
  );
};
